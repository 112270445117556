/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open sans", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  max-width: 90vw;
}

.login-menu {
  align-items: center;
}

.login-menu ul {
  list-style-type: none;
  float: right;
  align-items: center;
}

.login-menu ul li {
  float: left;
  margin-right: 40px;
  font-family: "Poppins";
  font-size: 1.2rem;
  font-weight: 900;
}

.login-menu ul li a {
  color: rgb(65, 62, 62);
  text-decoration: none;
}

.login-menu ul li a:hover {
  color: #e72064;
}

.login-menu-mobile a {
  color: rgb(65, 62, 62);
  text-decoration: none;
}

.logo-cc {
  width: 150px;
}

.note-box {
  display: flex;
  align-items: center;
  border: 1px solid #b1a7a6;
  padding: 20px;
}

@media only screen 
and (max-device-width: 600px) {
  .logo-cc {
    width: 130px;
    margin-top: 5px;
  }
}
