.boxListBranches {
  color: white;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gridFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textLeft {
  text-align: left;
}

.imgBranch {
  width: 350px;
}

.btnNoDelete {
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
}

.btnAgreeDelete {
  background-color: #DA0067 !important;
  color: white !important;
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
}

@media only screen and (max-width: 900px) {
  .boxListBranches {
    background-color: #DA0067;
    height: 300px;
  }

  .tblList {
    overflow-x: scroll;
  }

  .tblList::-webkit-scrollbar {
    width: 14px;
    height: 8px;
  }
  .tblList::-webkit-scrollbar:window-inactive {
    display: none;
  }
  
  .tblList::-webkit-scrollbar-track-piece {
    border-radius: 2px;
    background-color: white;
    background-clip: content-box;
    border-radius: 6px;
    border: solid 4px transparent;
  }
  .tblList::-webkit-scrollbar-thumb  {
    height: 5px;
    width: 4px;
    background-color: grey;
    border-radius: 6px;
    box-shadow: 0 3px 6px -2px rgba(51,0,51,0.5);
  }
  
}