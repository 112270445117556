@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open sans", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  max-width: 90vw;
}

.login-menu {
  align-items: center;
}

.login-menu ul {
  list-style-type: none;
  float: right;
  align-items: center;
}

.login-menu ul li {
  float: left;
  margin-right: 40px;
  font-family: "Poppins";
  font-size: 1.2rem;
  font-weight: 900;
}

.login-menu ul li a {
  color: rgb(65, 62, 62);
  text-decoration: none;
}

.login-menu ul li a:hover {
  color: #e72064;
}

.login-menu-mobile a {
  color: rgb(65, 62, 62);
  text-decoration: none;
}

.logo-cc {
  width: 150px;
}

.note-box {
  display: flex;
  align-items: center;
  border: 1px solid #b1a7a6;
  padding: 20px;
}

@media only screen 
and (max-device-width: 600px) {
  .logo-cc {
    width: 130px;
    margin-top: 5px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

iframe {
  display: none;
}

.button-like-link {
    background: none!important;
    border: none;
    padding: 0!important;
    margin: auto;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
}

.button-like-link:disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: rgb(211, 211, 211);
}
    .svg-cc {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 150px;
    }

    .square-cc {
    stroke-dasharray:50;
    stroke-dashoffset:-50;
    -webkit-animation: draw-square-cc 2s infinite;
            animation: draw-square-cc 2s infinite;
    }

    @-webkit-keyframes draw-square-cc {
    from {
        stroke-dashoffset: -100;
    }
    to {
        stroke-dashoffset: 100;
    }
    }

    @keyframes draw-square-cc {
    from {
        stroke-dashoffset: -100;
    }
    to {
        stroke-dashoffset: 100;
    }
    }
p {
  color: #383838;
}

.pointAdhesion{
    font-weight: bold;
  }

  .WelcomeCommerceButton{
    width: 100%;
  }
  

.boxListBranches {
  color: white;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gridFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textLeft {
  text-align: left;
}

.imgBranch {
  width: 350px;
}

.btnNoDelete {
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
}

.btnAgreeDelete {
  background-color: #DA0067 !important;
  color: white !important;
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
}

@media only screen and (max-width: 900px) {
  .boxListBranches {
    background-color: #DA0067;
    height: 300px;
  }

  .tblList {
    overflow-x: scroll;
  }

  .tblList::-webkit-scrollbar {
    width: 14px;
    height: 8px;
  }
  .tblList::-webkit-scrollbar:window-inactive {
    display: none;
  }
  
  .tblList::-webkit-scrollbar-track-piece {
    border-radius: 2px;
    background-color: white;
    background-clip: content-box;
    border-radius: 6px;
    border: solid 4px transparent;
  }
  .tblList::-webkit-scrollbar-thumb  {
    height: 5px;
    width: 4px;
    background-color: grey;
    border-radius: 6px;
    box-shadow: 0 3px 6px -2px rgba(51,0,51,0.5);
  }
  
}
.comeBackPosition {
  display: flex;
  align-items: center;
}
