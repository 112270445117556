p {
  color: #383838;
}

.pointAdhesion{
    font-weight: bold;
  }

  .WelcomeCommerceButton{
    width: 100%;
  }
  
